import React from "react";
import { Outlet } from "react-router-dom";

import Navbar from "../Navbar";

export default function PageLayout() {
    return (
        <React.Fragment>
            <Navbar />
            <Outlet />
        </React.Fragment>
    );
}
