import { createSlice } from '@reduxjs/toolkit';

export const transactionDataSlice = createSlice({
    name: 'transactionData',
    initialState: {
        value: {},
    },
    reducers: {
        setTransactionData: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setTransactionData } = transactionDataSlice.actions;

// export const selectAssetData = state => state.assetData.value;

export default transactionDataSlice.reducer;