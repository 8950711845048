// format big numbers
// export const formatNumber = (number) => {
//     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     }

// export function formatNumber(number){
//     // adds commas to numbers
//     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

export function abbreviateNumber(number,decimals){
    if(number === null || number === undefined){
        return null
    }
    
    // set number_magnitude = to absolute value
    let number_magnitude = Math.abs(number)

    // 2 decimal places => 100, 3 => 1000, etc
    if(number_magnitude < 1000){
        return number
    }
    else if(number_magnitude < 1000000){
        return (number/1000).toFixed(decimals) + 'K'
    }
    else if(number_magnitude < 1000000000){
        return (number/1000000).toFixed(decimals) + 'M'
    }
    else if(number_magnitude < 1000000000000){
        return (number/1000000000).toFixed(decimals) + 'B'
    }
    else if(number_magnitude < 1000000000000000){
        return (number/1000000000000).toFixed(decimals) + 'T'
    }
    else{
        return (number/1000000000000000).toFixed(decimals) + 'Q'
    }
}