import { createSlice } from '@reduxjs/toolkit';

export const watchlistDataSlice = createSlice({
    name: 'watchlistData',
    initialState: {
        value: {},
    },
    reducers: {
        setWatchlistData: (state, action) => {
            // state.value = action.payload;
            state.value = Object.assign({}, state.value , action.payload)
        }
    }
});

export const { setWatchlistData } = watchlistDataSlice.actions;

// export const selectAssetData = state => state.assetData.value;

export default watchlistDataSlice.reducer;