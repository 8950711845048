import React, { useState, useEffect } from 'react'

import '../css/SummaryBanner.css';

export default function SummaryBanner(props) {
    const [stockData, setStockData] = useState(props.stockData)

    function getTotalValue(){
        // let assets = this.asset_json
        // console.log("hhh",Object.keys(assets))
        // stockData
        var keys = Object.keys(stockData)
        let sum = 0
        for (let i =0; i < keys.length;i++){
          let stock = stockData[keys[i]]
          if(keys[i] == "CASH"){
            // sum = sum + stock.amount
          }
          else{
            sum = sum + (stock.price*stock.shares)
          }
  
        }
        return sum
    }

    function getTotalCost(){
        // let assets = this.asset_json
        // console.log("hhh",Object.keys(assets))
        // stockData
        var keys = Object.keys(stockData)
        let sum = 0
        for (let i =0; i < keys.length;i++){
          let stock = stockData[keys[i]]
          if(keys[i] == "CASH"){
            // sum = sum + stock.amount
          }
          else{
            sum = sum + (stock.purchase_price*stock.shares) + stock.purchase_fee
          }
  
        }
        return sum
    }

    function getTotalDividend(){
        // console.log('div',this.stocks)
  
        var keys = Object.keys(stockData)
        let total_dividend = 0
        for (let i =0; i < keys.length;i++){
          if(keys[i] != "CASH"){
            let stock = stockData[keys[i]]
            if(stock.scraped_info && stock.scraped_info.dividend != null){
              total_dividend = total_dividend + (stock.scraped_info.dividend * stock.shares)
            }
          }
        }
  
        return total_dividend
    }

    useEffect(() => {    
        setStockData(props.stockData)
    },[props.stockData])

    return (
        <div className="stockSummaryBanner">
            <div className="col">
                <p>Total Value: ${getTotalValue().toFixed(2)}</p>
            </div>
            <div className="col">
                <p>Total Cost: ${getTotalCost().toFixed(2)}</p>
            </div>
            <div className="col">
                <p>Cash on Hand: ${stockData.CASH && stockData.CASH.amount}</p>
                <br/>
                <p>Total Dividend: ${getTotalDividend()}</p>
            </div>
            <div className="col">
                <p>Profit Margin: ${(getTotalValue() - getTotalCost()).toFixed(2)}</p>
                <br/>
                {/* TODO need to add cash to this equation */}
                <p>% Return: {(((getTotalValue() - getTotalCost()) / getTotalCost()) * 100).toFixed(1)}</p>
            </div>
        </div>
    )
}