/* https://codepen.io/jo_Geek/pen/EOKvLE */

import React, { useEffect, useState } from 'react';
import styles from '../../css/components/common/NightSky.module.css'

function NightSky() {
  const [stars, setStars] = useState([]);
  const nightsky = ["#280F36", "#632B6C", "#BE6590", "#FFC1A0", "#FE9C7F"];

  useEffect(() => {
    const newStars = [];
    // Replace your for loops here to push JSX elements into newStars
    // For example:

    let rand = getRandomInt(0, 5);
    let selected_star_style = styles[`star_${rand}`];

    for (let i = 0; i < 500; i++) {
      newStars.push(
        <div
          key={i}
          className={`${styles.star} ${selected_star_style}`} 
          style={{
            top: `${getRandomInt(0, 100)}vh`, 
            left: `${getRandomInt(0, 100)}vw`, 
            // animationDuration: `${getRandomInt(1, 7)}s`,
            backgroundColor: nightsky[Math.floor(getRandomInt(0, nightsky.length))],
            // zIndex: 10
          }}
        />
      );
    }
    // console.log(newStars);
    setStars(newStars);
  }, []);

  return (
    <div className={styles.sky}>
      {/* <div className="mountains">
        <div className="mountain-1"></div>
        <div className="mountain-2"></div>
        <div className="land-1"></div>
        <div className="land-2"></div>
        <div className="land-3"></div>
      </div> */}
      {/* <div className="mountains-base"></div> */}
      {/* <div className="light-base"></div> */}
      <div className={styles.stars}>{stars}</div>
      <div className={styles.stars_cross}></div>
      <div className={styles.stars_cross_aux}></div>
    </div>
  );
}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export default NightSky;
