import React, { useState, useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
// import { useSelector,useDispatch } from 'react-redux'
// import { resolvePath, useNavigate } from 'react-router-dom';
// import { setAssetData } from '../redux/actions'

// import { getStockData } from '../lib/aws_api';

// import ProSwitch from '../components/common/ProSwitch'
import AddTransactionModal from '../components/pages/Transactions/AddTransactionModal'
import DeleteTransactionModal from '../components/pages/Transactions/DeleteTransactionModal'


import LoadingSpinner from '../components/common/LoadingSpinner';

import '../css/pages/Transactions.css'
import NightSky from '../components/common/NightSky';


export default function Transactions(){
    let transactionData = useSelector(state => state.transactionData.value)
    const [isLoading, setIsLoading] = useState(false)
    const [openModal, setOpenModal] = useState(null)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    // let selectedRow = null

    function handleRowCheckbox(e){
        // row number of selected checkbox
        let row_number = Number(e.target.id)

        //uncheck all other checkboxes
        let checkboxes = document.getElementsByClassName('transaction-row-checkbox')
        for(let i = 0; i < checkboxes.length; i++){
            if(i !== row_number){
                checkboxes[i].checked = false
            }
        }

        //set selected row if checkbox is checked
        if(e.target.checked){
            setSelectedRow(row_number)
        }
        else{
            setSelectedRow(null)
        }
    }


    function test_handle(state){
        setIsLoading(state)
        console.log('111',state)
    }


    return (
        <div className='trasaction-page-container'>
            {isLoading ? <LoadingSpinner /> :
            <>
                <br/>

                <div className='transaction-table'>
                    <div className='transaction-table-menu'>
                        <button onClick={() => setOpenModal(!openModal)}>Add</button>
                        <button onClick={() => setOpenDeleteModal(!openDeleteModal)} disabled={selectedRow !== null ? false : true}>Delete</button>
                        <button disabled={selectedRow !== null ? false : true}>Edit</button>
                    </div>
                    <div className='transaction-table-menu-padding'>
                    </div>
                    {transactionData.map((transaction,index) => {

                        if(transaction.type === 'purchase'){
                            return (
                                <div className='transaction-row'>
                                    <h3 className='transaction-row-date'>{transaction.date}</h3>

                                    <div className='transaction-row-heading'>
                                        <h6 className='transaction-row-type'>{transaction.type}</h6>
                                        <h4 className='transaction-row-ticker'>{transaction.data.ticker}</h4>
                                    </div>

                                    <h6 className='transaction-row-shares'>Shares: {transaction.data.shares}</h6>
                                    <div className='transaction-row-purchase-info'>
                                        <h6>Purchase Price: {transaction.data.purchase_price}</h6>
                                        <h6>Purchase Date: {transaction.data.purchase_date}</h6>
                                        <h6>Purchase Fee: {transaction.data.purchase_fee}</h6>
                                        <h6>Account: {transaction.data.account}</h6>
                                    </div>
                                    <input id={index} className='transaction-row-checkbox' type="checkbox" onChange={handleRowCheckbox}/>
                                </div>
                            )
                        }


                        return (
                            <div className='transaction-row'>
                                <h3 className='transaction-row-date'>{transaction.date}</h3>
                                <div className='transaction-row-heading'>
                                    <h6 className='transaction-row-type'>{transaction.type}</h6>
                                </div>
                                <h6 className='transaction-row-shares'>Amount: {transaction.data.amount}</h6>
                                <div className='transaction-row-purchase-info'>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <NightSky />

                <AddTransactionModal open={openModal} loadingFunc={(state) => test_handle(state)}/>
                <DeleteTransactionModal open={openDeleteModal}  loadingFunc={(state) => setIsLoading(state)} transaction={transactionData[selectedRow]} row_number={selectedRow}/>

                <br/>
            </>
            }
            {/* {selectedRow !== null ? JSON.stringify(transactionData[selectedRow]) : null} */}
        </div>
    )
}