import styles from '../../../css/components/common/tags/Tag.module.css';

function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

function Tag(props) {
  return (
    <div className={styles.tag}>
        <div className={styles.coloredSquare} style={{backgroundColor: stringToColor(props.text)}}></div>
        {props.text}
        {props.deleteFunc && <button type='button' className={styles.delete_button} onClick={props.deleteFunc}>x</button>}
        {/* <button type='button' onClick={() => handleRemoveTag(index)}>x</button> */}
    </div>
  );
}

export default Tag;