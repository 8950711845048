import { createSlice } from '@reduxjs/toolkit';

export const accessTokenSlice = createSlice({
    name: 'accessToken',
    initialState: {
        value: '',
    },
    reducers: {
        setAccessToken: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setAccessToken } = accessTokenSlice.actions;

// export const selectAccessToken = state => state.accessToken.value;

export default accessTokenSlice.reducer;