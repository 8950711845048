import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import styles from '../css/pages/Portfolio.module.css'

import { abbreviateNumber } from '../lib/format'


import LoadingSpinner from '../components/common/LoadingSpinner';
import YearRangeChart from '../components/charts/YearRangeChart';

import AddPositionModal from '../components/pages/Portfolio/AddPositionModal'
import DeletePositionModal from '../components/pages/Portfolio/DeletePositionModal'
import EditPositionModal from '../components/pages/Portfolio/EditPositionModal';
import NightSky from '../components/common/NightSky';


export default function Portfolio(){
    let assetData = useSelector(state => state.assetData.value)
    const [selected_ticker, setSelectedTicker] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const [openAddPositionModal, setOpenAddPositionModal] = useState(false)
    const [openDeletePositionModal, setOpenDeletePositionModal] = useState(false)
    const [openEditPositionModal, setOpenEditPositionModal] = useState(false)




    function handleRowCheckbox(event){
        if(event.target.checked === true){
            //uncheck all other checkboxes
            let row_number = Number(event.target.name)

            let checkboxes = document.getElementsByClassName('position-row-checkbox')


            for(let i = 0; i < checkboxes.length; i++){
                if(i !== row_number - 1){
                    // let checkbox = document.getElementsByName(i)
                    let checkbox = checkboxes[i]

                    checkbox.checked = false
                }
            }

            setSelectedTicker(event.target.id)
        }
        else{
            setSelectedTicker(null)
        }
    }

    return (
        <div className={styles.portfolio_page_container}>
            {isLoading ? <LoadingSpinner /> :

            <>
            <h1>Positions</h1>
            <div className={styles.positions_table_container}>
                <div className={styles.portfolio_header_row}>
                    <div className={styles.checkbox_row_section}>
                    </div>
                    <div className={styles.row_section}>
                        <h5>Ticker</h5>
                    </div>
                    <div className={styles.row_section}>
                        <h5>Purchase Date</h5>
                    </div>
                    <div className={styles.row_section}>
                        <h5>Asset Type</h5>
                    </div>
                    <div className={styles.row_section}>
                        <h5>Price</h5>
                    </div>
                    <div className={styles.row_section}>
                        <h5>Shares</h5>
                    </div>
                    <div className={styles.row_section}>
                        <h5>Value</h5>
                    </div>
                    <div className={styles.row_section}>
                        <h5>Purchase Price</h5>
                    </div>
                    <div className={styles.checkbox_row_section}>
                    </div>
                </div>
                <div className={styles.positions_table_data_section}>

                    {Object.keys(assetData).map((key,index) => {
                        if(key !== "CASH"){
                            let asset = assetData[key]
                            return (
                                <div className={styles.portfolio_row}>
                                    <div className={styles.checkbox_row_section}>
                                        {index}
                                    </div>
                                    <div className={styles.row_section}>
                                        <h4>{asset.ticker}</h4>
                                    </div>
                                    <div className={styles.row_section}>
                                        <h6>{asset.purchase_date}</h6>
                                    </div>
                                    <div className={styles.row_section}>
                                        <h6>{asset.asset_type}</h6>
                                    </div>
                                    <div className={styles.row_section}>
                                        <h6>${asset.price}</h6>
                                    </div>
                                    <div className={styles.row_section}>
                                        <h6>{asset.shares}</h6>
                                    </div>
                                    <div className={styles.row_section}>
                                        <h6>${(asset.price * asset.shares).toFixed(2)}</h6>
                                    </div>
                                    <div className={styles.row_section}>
                                        <h6>${asset.purchase_price}</h6>
                                    </div>
                                    {/* {asset.scraped_info ? JSON.stringify(asset.scraped_info) : null} */}

                                    {/* { asset.scraped_info && asset.asset_type === 'Equity' ?
                                    <>
                                        <div className={styles.row_section}>
                                            <h6>Dividend: {asset.scraped_info.dividend}</h6>
                                        </div>
                                        <div className={styles.row_section}>
                                            <h6>ROA: {asset.scraped_info.roa}</h6>
                                        </div>
                                    </>
                                        : null
                                    }

                                    { asset.scraped_info && asset.asset_type === 'Crypto' ?
                                        <>
                                            <div className={styles.row_section}>
                                            </div>
                                            <div className={styles.row_section}>
                                            </div>
                                        </>
                                        : null
                                    } */}

                                    <div className={styles.checkbox_row_section}>
                                        <input className={'position-row-checkbox'} id={key} name={index} type="checkbox" onChange={handleRowCheckbox}/>
                                    </div>

                                    {/* check if asset has key */}

                                    {/* {asset.scraped_info.earnings ? asset.scraped_info.earnings : null} */}
                                    {/* <h6>Asset Type: {asset.scraped_info}</h6> */}
                                    {/* <h6>Asset Type: {asset.asset_type}</h6> */}
                                    {/* <h6>Earnings: {asset.scraped_info.earnings}</h6>
                                    <h6>PE Ratio: {asset.scraped_info.pe_ratio}</h6>
                                    <h6>Market Cap: {asset.scraped_info.market_cap}</h6>
                                    <h6>Dividend: {asset.scraped_info.dividend}</h6>
                                    <h6>ROA: {asset.scraped_info.roa}</h6> */}
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
            {/* <button className={styles.menu_button} onClick={() => setOpenModal(!openModal)}>Add Asset</button> */}
            <div className={styles.right_side_container}>

                <button className={styles.add_position_button} onClick={() => setOpenAddPositionModal(!openAddPositionModal)}>Add Position</button>

                <div className={styles.selected_asset_details_container}>
                    {selected_ticker ?
                        <>
                            <DeleteIcon className={styles.delete_button} onClick={() => setOpenDeletePositionModal(!openDeletePositionModal)}/>
                            <EditIcon className={styles.edit_button} onClick={() => setOpenEditPositionModal(!openEditPositionModal)}/>


                            <h2 className={styles.asset_title}>{selected_ticker}</h2>
                            <h3>Price: {assetData[selected_ticker].price}</h3>


                            {assetData[selected_ticker].scraped_info ?
                            <div className={styles.asset_info_container}>
                                <div className={styles.info_container_left_half}>

                                    <h6>Market Cap: {abbreviateNumber(assetData[selected_ticker].scraped_info.market_cap,3)}</h6>

                                    {assetData[selected_ticker].asset_type === 'Equity' ?
                                        <>
                                            <h6>Earnings: {abbreviateNumber(JSON.parse(assetData[selected_ticker].scraped_info.yearly_earnings_data).earnings[0],3)}</h6>
                                            <h6>PE Ratio: {assetData[selected_ticker].scraped_info.pe_ratio}</h6>
                                            <h6>Dividend: {assetData[selected_ticker].scraped_info.dividend}</h6>
                                            <h6>ROA: {assetData[selected_ticker].scraped_info.roa}</h6>

                                        </>
                                        : null
                                    }

                                    {/* {assetData[selected_ticker].asset_type === 'Crypto' ?
                                        <>
                                        </>
                                        : null
                                    } */}

                                </div>
                                <div className={styles.info_container_right_half}>


                                    <h5>Year Range: {assetData[selected_ticker].scraped_info.year_range[0]} - {assetData[selected_ticker].scraped_info.year_range[1]}</h5>
                                    <YearRangeChart price={assetData[selected_ticker].price} year_range={assetData[selected_ticker].scraped_info.year_range} />

                                </div>
                            </div>
                            : <p>Asset Not Yet Scraped</p>
                            }
                        </>
                        : <p>Select Position You Will</p>
                    }

                </div>
            </div>

            <AddPositionModal open={openAddPositionModal} loadingFunc={(state) => setIsLoading(state)}/>
            <DeletePositionModal open={openDeletePositionModal} asset_key={selected_ticker} loadingFunc={(state) => setIsLoading(state)} clearSelectedTicker={() => setSelectedTicker(null)}/>
            <EditPositionModal open={openEditPositionModal} loadingFunc={(state) => setIsLoading(state)} asset_key={selected_ticker} asset={assetData[selected_ticker]}/>
            </>
            }
            <NightSky />
        </div>
    )
}