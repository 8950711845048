import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { addTransaction } from '../../../lib/aws_api';

import { setTransactionData } from '../../../redux/slices/transactionDataSlice'

import '../../../css/components/pages/Transactions/AddTransactionModal.css'

export default function AddTransactionModal(props) {
    const dispatch = useDispatch()
    let accessToken = useSelector(state => state.accessToken.value)
    let user = useSelector(state => state.user.value)

    const [transaction_type, setTransactionType] = useState('purchase')

    function handleTypeSelect(e){
        setTransactionType(e.target.value)
    }

    async function handleSubmit(e){
        e.preventDefault()
        props.loadingFunc(true)

        //parse form data from event
        let ticker = e.target.elements.ticker.value
        let shares = e.target.elements.shares.value
        let purchase_date = e.target.elements.purchase_date.value
        let purchase_price = e.target.elements.purchase_price.value
        let purchase_fee = e.target.elements.purchase_fee.value
        let account = e.target.elements.account.value

        let transaction = {
            ticker,
            shares,
            purchase_date,
            purchase_price,
            purchase_fee,
            account
        }

        let transaction_data = await addTransaction(accessToken,user,transaction)

        dispatch(setTransactionData(transaction_data.transactions))

        props.loadingFunc(false)
    }

    if(!props.open){
        return null
    }

    return (
        <div className='add-transaction-modal'>
            <h2>Add Transaction</h2>
            <form className='add-transaction-form' onSubmit={handleSubmit}>
                {/* <label>Transaction Type: </label> */}
                <select value={transaction_type} onChange={handleTypeSelect}>
                    <option value='purchase'>Purchase</option>
                    <option value='sell'>Sell</option>
                    <option value='cash_deposit'>Cash Deposit</option>
                </select>
                { transaction_type === 'purchase' ?
                    <React.Fragment>
                        <div><label>Ticker: </label><input type='text' name='ticker' placeholder='ticker'/></div>
                        <div><label>Shares: </label><input type='number' name='shares' placeholder='shares'/></div>
                        <div><label>Purchase Date: </label><input type='date' name='purchase_date' placeholder='purchase date'/></div>
                        <div><label>Purchase Price: </label><input type='number' name='purchase_price' placeholder='purchase price'/></div>
                        <div><label>Purchase Fee: </label><input type='number' name='purchase_fee' placeholder='purchase fee'/></div>
                        <div><label>Account: </label><input type='text' name='account' placeholder='account'/></div>
                    </React.Fragment>
                    : <p>"Under Construction"</p> 
                }
                <button type='submit'>Submit</button>
            </form>
        </div>
    )
}
