import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { createAsset } from '../../../lib/aws_api';

import { setAssetData } from '../../../redux/slices/assetDataSlice'


import styles from '../../../css/components/pages/Portfolio/AddPositionModal.module.css'

export default function AddPositionModal(props) {
    const dispatch = useDispatch()
    let accessToken = useSelector(state => state.accessToken.value)
    let user = useSelector(state => state.user.value)



    async function handleSubmit(e){
        e.preventDefault()
        props.loadingFunc(true)

        //parse form data from event
        let ticker = e.target.elements.ticker.value
        let asset_type = e.target.elements.asset_type.value
        let shares = Number(e.target.elements.shares.value)
        let purchase_price = Number(e.target.elements.purchase_price.value)
        let purchase_date = e.target.elements.purchase_date.value
        let purchase_fee = Number(e.target.elements.purchase_fee.value)
        let industry = e.target.elements.industry.value
        let sector = e.target.elements.sector.value
        let account = e.target.elements.account.value

        let asset = {
            ticker,
            asset_type,
            shares,
            purchase_price,
            purchase_date,
            purchase_fee,
            industry,
            sector,
            account
        }

        let asset_data = await createAsset(user,accessToken,asset)

        // let watchlist_data = result.data.watchlist_data
        dispatch(setAssetData(asset_data))

        props.loadingFunc(false)
    }

    if(!props.open){
        return null
    }

    return (
        <div className={styles.add_position_modal}>
            <h2>Add Position</h2>
            <form className={styles.add_position_form} onSubmit={handleSubmit}>
                <div><label>Ticker: </label><input type='text' name='ticker' placeholder='ticker'/></div>
                <div><label>Asset Type: </label><input type='text' name='asset_type' placeholder='asset_type'/></div>
                <div><label>Shares: </label><input type='number' name='shares' placeholder='shares'/></div>
                <div><label>Purchase Price: </label><input type='number' name='purchase_price' placeholder='purchase price'/></div>
                <div><label>Purchase Date: </label><input type='date' name='purchase_date' placeholder='purchse date'/></div>
                <div><label>Purchase Fee: </label><input type='number' name='purchase_fee' placeholder='purchse fee'/></div>
                <div><label>Industry: </label><input type='text' name='industry' placeholder='industry'/></div>
                <div><label>Sector: </label><input type='text' name='sector' placeholder='sector'/></div>
                <div><label>Account: </label><input type='text' name='account' placeholder='account'/></div>

                <button type='submit'>Submit</button>
            </form>
        </div>
    )
}
