import React, { useState, useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from "react-router-dom";

import { setAssetData } from '../redux/slices/assetDataSlice'

import { abbreviateNumber } from '../lib/format'

// import { resolvePath, useNavigate } from 'react-router-dom';
// import { setAssetData } from '../redux/actions'

// import { getStockData } from '../lib/aws_api';

// import ProSwitch from '../components/common/ProSwitch'

import { updateAssetNotes } from '../lib/aws_api';


import LoadingSpinner from '../components/common/LoadingSpinner';
import YearlyDataTable from '../components/common/YearlyDataTable';

import '../css/pages/AssetDetails.css'
import NightSky from '../components/common/NightSky';





export default function AssetDetails(){
    const navigate = useNavigate();
    const dispatch = useDispatch()
    let [searchParams, setSearchParams] = useSearchParams();
    let query_param_ticker = searchParams.get("ticker")
    let accessToken = useSelector(state => state.accessToken.value)
    const [isLoading, setIsLoading] = useState(false)

    // let selected_asset_key = ''

    let rawAssetData = useSelector(state => state.assetData.value)
    //filter out CASH from assetData
    let assetData = {}
    Object.keys(rawAssetData).map((key) => {
        let asset = rawAssetData[key]
        if(key !== 'CASH'){
            assetData[key] = asset
        }
    })

    let initial_asset = assetData[Object.keys(assetData)[0]].ticker
    if(query_param_ticker){
        initial_asset = query_param_ticker
    }





    const [selected_ticker, setSelectedTicker] = useState(initial_asset)
    const [notesData, setNotesData] = useState(assetData[Object.keys(assetData)[0]].ticker.notes)


    let selected_assetData = {}
    let selected_asset_list = []

    Object.keys(assetData).map((key) => {
        let asset = assetData[key]
        if(asset.ticker === selected_ticker){
            selected_assetData[key] = asset
            // selected_asset_key = key
        }
    })

    //convert object to list
    selected_asset_list = Object.keys(selected_assetData).map((key) => {
        return selected_assetData[key]
    })


    function handleAssetSelect(event){
        // setSelectedTicker(event.target.value)
        navigate(`/assetdetails?ticker=${event.target.value}`)
    }

    async function handleSaveButton(){
        setIsLoading(true)
        let res = await updateAssetNotes(accessToken,'personal',selected_ticker, notesData)
        let stock_data = res.data.stock_data

        let new_data = JSON.parse(JSON.stringify(rawAssetData))

        for(let asset_key in new_data){
            console.log('asset_key',asset_key)
            if(new_data[asset_key].ticker === selected_ticker){
                new_data[asset_key].notes = stock_data[asset_key].notes
            }
        }
        dispatch(setAssetData(new_data))
        setIsLoading(false)
    }

    useEffect(() => {
        console.log('useEffect',query_param_ticker)
        if(query_param_ticker){
            setSelectedTicker(query_param_ticker)
        }
        else{
            setSelectedTicker(assetData[Object.keys(assetData)[0]].ticker)
        }

    }, [query_param_ticker])

    useEffect(() => {
        //reduce assetData to only the selected ticker
        //TODO may stop after 1st match
        Object.keys(assetData).map((key) => {
            let asset = assetData[key]
            if(asset.ticker === selected_ticker){
                selected_assetData[key] = asset
                // selected_asset_key = key
            }
        })

        //convert object to list
        selected_asset_list = Object.keys(selected_assetData).map((key) => {
            return selected_assetData[key]
        })

        if(selected_asset_list[0].notes){
            setNotesData(selected_asset_list[0].notes)
        }
        else{
            setNotesData('')
        }

    }, [selected_ticker])


    function assetDetailsContainer(props){
        return(
            <React.Fragment>
                {/* <h1>{selected_asset_list[0].ticker}</h1>
                <p>Purchase Price: {selected_asset_list[0].purchase_price}</p>
                <p>Purchase Date: {selected_asset_list[0].purchase_date}</p>
                <p>Asset Type: {selected_asset_list[0].asset_type}</p>
                <p>Sector: {selected_asset_list[0].sector}</p>
                <p>Day Change %: {selected_asset_list[0].day_change_percent}</p> */}

                {/* select with options from assetData keys*/}
                <div>
                    <select value={selected_ticker} onChange={handleAssetSelect}>
                        {Object.keys(assetData).map((key) => {
                            return <option value={assetData[key].ticker}>{key}</option>
                        })}
                    </select>
                </div>


                <div className="asset-info-container">
                    <div className="asset-header-top-line">
                        <div className="asset-header-ticker">
                            <h1>{selected_asset_list[0].ticker}</h1>
                        </div>
                        <div className="asset-header-price">
                            ${selected_asset_list[0].price}
                        </div>
                    </div>
                    <div className="info-container">
                        <div className="purchase-info">
                            {/* <div className="asset-header-shares"> */}
                                <h5>Shares: {selected_asset_list[0].shares}</h5>
                            {/* </div> */}
                            {/* <div className="asset-header-purchase-price"> */}
                                <h5 className="total-value">Total Value: {((selected_asset_list[0].price*selected_asset_list[0].shares)).toFixed(2)}</h5>

                                <h5>Total Cost: {((selected_asset_list[0].purchase_price*selected_asset_list[0].shares) + selected_asset_list[0].purchase_fee).toFixed(2)}</h5>
                            {/* </div> */}
                            {/* <div className="asset-header-purchase-price"> */}
                                <h6>Purchase Price: {selected_asset_list[0].purchase_price}</h6>
                            {/* </div> */}
                            {/* <div className="asset-header-purchase-fee"> */}
                                <h6>Purchase Fee: {selected_asset_list[0].purchase_fee}</h6>
                            {/* </div> */}
                            {/* <div className="asset-header-purchase-date"> */}
                                <h6>Purchase Date: {selected_asset_list[0].purchase_date}</h6>
                            {/* </div> */}
                        </div>

                        <br/>

                        <div className="asset-type-info">
                            <h6>Asset Type: {selected_asset_list[0].asset_type}</h6>
                            <h6>Industry: {selected_asset_list[0].industry}</h6>
                            <h6>Sector: {selected_asset_list[0].sector}</h6>
                        </div>

                        <br/>

                        <div className="scraped-info">
                            <h6>Market Cap: {selected_asset_list[0].scraped_info && abbreviateNumber(selected_asset_list[0].scraped_info.market_cap,3)}</h6>
                            <h6>Dividend: {selected_asset_list[0].scraped_info && selected_asset_list[0].scraped_info.dividend}</h6>
                            <h6>Earnings: {selected_asset_list[0].scraped_info.yearly_earnings_data && abbreviateNumber(JSON.parse(selected_asset_list[0].scraped_info.yearly_earnings_data).earnings[0],3)}</h6>
                            <h6>Year Range: {selected_asset_list[0].scraped_info && selected_asset_list[0].scraped_info.year_range[0]} - {selected_asset_list[0].scraped_info && selected_asset_list[0].scraped_info.year_range[1]}</h6>
                            {/* <h6>Week Range: {selected_asset_list[0].scraped_info && selected_asset_list[0].scraped_info.year_range[0]} - {selected_asset_list[0].scraped_info && selected_asset_list[0].scraped_info.year_range[1]}</h6> */}
                        </div>
                    </div>
                    {/* {selected_asset_key} */}
                    <br/>


                    <a href={`https://finance.yahoo.com/quote/${selected_asset_list[0].ticker}`}>Yahoo Finance</a>

                    {/* {rawAssetData[Object.keys(rawAssetData)[0]].ticker} */}
                    {/* {JSON.stringify(selected_asset_list[0].scraped_info)} */}

                    {/* {Object.keys(selected_asset_list[0]).map((key) => {
                        return <h5>{key}</h5>
                    })} */}
                </div>

                <div className="notes-container">
                    <label className="notes-label">Notes</label>
                    <textarea className="notes-text-box" type="text" id="asset-notes" value={notesData} onChange={event => setNotesData(event.target.value)}/>
                    <button className="notes-save-button" onClick={() => handleSaveButton()}>Save</button>
                </div>

                <div className="yearly-financial-table-container">
                    <YearlyDataTable stock={selected_asset_list[0]}/>
                </div>


            </React.Fragment>
        );
    }

    return (
        <div className="asset-details-page-container">
            {isLoading ? <LoadingSpinner /> : assetDetailsContainer()}
            <NightSky />
        </div>
    )
}