import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { watchlistsDeleteAsset } from '../../../lib/aws_api';

import { setWatchlistData } from '../../../redux/slices/watchlistDataSlice'

import '../../../css/components/pages/Watchlist/DeleteAssetModal.css'


export default function WatchlistsDeleteAssetModal(props) {
    const dispatch = useDispatch()
    let accessToken = useSelector(state => state.accessToken.value)
    let user = useSelector(state => state.user.value)

    async function handleSubmit(e){
        e.preventDefault()
        props.loadingFunc(true)

        let watchlist_data = await watchlistsDeleteAsset(user,accessToken,props.selectedTicker,props.selectedWatchlist)

        dispatch(setWatchlistData(watchlist_data))

        props.setOpenDeleteAssetModal(false)
        props.loadingFunc(false)

    }

    if(!props.open){
        return null
    }

    return (
        <div className='delete-watchlist-modal'>
            <h2>Delete "{props.selectedTicker}"" from "{props.selectedWatchlist}" Watchlist</h2>
            <h5>Are you sure you want to delete this asset from watchlist?</h5>
            <h5>{props.selectedTicker}</h5>

            {/* <div className='transaction-details'>
                <div><label>Ticker: </label><p>{transaction.data.ticker}</p></div>
                <div><label>Shares: </label><p>{transaction.data.shares}</p></div>
                <div><label>Purchase Date: </label><p>{transaction.data.purchase_date}</p></div>
                <div><label>Purchase Price: </label><p>{transaction.data.purchase_price}</p></div>
                <div><label>Purchase Fee: </label><p>{transaction.data.purchase_fee}</p></div>
                <div><label>Account: </label><p>{transaction.data.account}</p></div>
            </div> */}

            <button onClick={handleSubmit}>Yes</button>
            <button>No</button>

            <br/>
            <br/>
        </div>
    )
}
