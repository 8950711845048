import { createSlice } from '@reduxjs/toolkit';

export const totalValueDataSlice = createSlice({
    name: 'totalValueData',
    initialState: {
        value: {},
    },
    reducers: {
        setTotalValueData: (state, action) => {
            // state.value = action.payload;
            state.value = Object.assign({}, state.value , action.payload)
        }
    }
});

export const { setTotalValueData } = totalValueDataSlice.actions;

// export const selectAssetData = state => state.assetData.value;

export default totalValueDataSlice.reducer;