import React, { useEffect, useState } from 'react'

import FilterComponent from '../../../components/common/FilterComponent';

import styles from '../../../css/components/pages/Watchlist/WatchlistTable.module.css'


export default function WatchlistTable(props) {
    let displayedWatchlist = props.displayedWatchlist

    return (
        <div className={styles.watchlist_table}>
            <div className={styles.watchlist_header_row}>
                <div className={styles.watchlist_checkbox_section}>
                </div>
                <div className={styles.watchlist_row_heading}>
                    Ticker
                </div>
                <div className={styles.watchlist_row_heading}>
                    Price
                </div>
                <div className={styles.watchlist_row_heading}>
                    Asset Type
                </div>
                <div className={styles.watchlist_row_heading}>
                    Day Change
                </div>
                <div className={styles.watchlist_checkbox_section}>
                </div>
            </div>

            <div className={styles.watchlist_table_data_section}>
                {Object.keys(displayedWatchlist).map((key,index) => {
                    let asset = displayedWatchlist[key]
                    return (
                        <div key={key} className={styles.watchlist_row}>
                            <div className={styles.watchlist_checkbox_section}>
                                {index}
                            </div>
                            <div className={styles.watchlist_row_heading}>
                                <h3>{asset.ticker}</h3>
                            </div>
                            <div className={styles.watchlist_row_heading}>
                                <h4>${asset.price}</h4>
                            </div>
                            <div className={styles.watchlist_row_heading}>
                                <h6>{asset.asset_type}</h6>
                            </div>
                            { asset.scraped_info ?
                                <div className={styles.watchlist_row_info}>
                                    <h6>Day Change: {asset.day_change.toFixed(3)} ({asset.day_change_percent.toFixed(2)}%)</h6>
                                    <h6>Open: {asset.open}</h6>
                                </div>
                            :
                            <>
                                <div className={styles.watchlist_row_info}>
                                    <h4>Asset not yet scraped</h4>
                                </div>
                            </>
                            }

                            <div className={styles.watchlist_checkbox_section}>
                                <a href={`https://finance.yahoo.com/quote/${asset.ticker}`}>YF</a>
                                <input className={'watchlist-row-checkbox'} id={key} name={index} type="checkbox" onChange={(e) => props.onRowCheckbox(e)} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
