import { configureStore } from '@reduxjs/toolkit';

// import accessToken from "./reducers/accessToken"
// import assetData from "./reducers/assetData"

import accessTokenReducer from "./slices/accessTokenSlice"
import assetDataReducer from "./slices/assetDataSlice"
import transactionDataReducer from "./slices/transactionDataSlice"
import totalValueDataReducer from "./slices/totalValueDataSlice"
import userDataReducer from './slices/userDataSlice';
import watchlistDataReducer from "./slices/watchlistDataSlice"

export default configureStore({
    reducer: {
        accessToken: accessTokenReducer,
        assetData: assetDataReducer,
        transactionData: transactionDataReducer,
        totalValueData: totalValueDataReducer,
        user: userDataReducer,
        watchlistData: watchlistDataReducer
    }
});