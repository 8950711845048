import React, { useEffect, useState } from 'react'

import styles from '../../../css/components/pages/Watchlist/WatchlistsListMenu.module.css'

import DeleteIcon from '@mui/icons-material/Delete';

export default function WatchlistsListMenu(props) {
    let watchlists_data = props.watchlists_data
    let selectedItem = props.selectedItem

    return (
        <div className={styles.left_menu_container}>
            <div key='All' className={selectedItem === 'All' ? styles.selected_menu_item : styles.menu_item} onClick={() => {props.handleListSelect("All")}}>All</div>
            {watchlists_data.watchlists.map((list_name) => {
                return (
                    <div key={list_name} className={selectedItem === list_name ? styles.selected_menu_item : styles.menu_item} onClick={() => {props.handleListSelect(list_name)}}>{list_name}</div>
                )
            })}

            <div className={styles.button_container}>
                <button className={styles.delete_list_button} onClick={props.handleOpenDeleteWatchlistModal}><DeleteIcon /></button>
                <button className={styles.add_list_button} onClick={props.handleOpenAddWatchlistModal}>+</button>
            </div>
        </div>
    )
}
