import {useState} from 'react'
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux'
import { setAccessToken } from '../redux/slices/accessTokenSlice'

import { AuthenticationDetails,CognitoUserPool,CognitoUser } from 'amazon-cognito-identity-js'

import { cognitoLogin } from '../lib/aws_api';
import { setUser } from '../redux/slices/userDataSlice';

import '../css/pages/Login.css'
import NightSky from '../components/common/NightSky';


export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleSignIn = e => {
        e.preventDefault()
    
        const username = document.getElementById('username').value
        const password = document.getElementById('password').value

        // cognitoLogin(username,password)
        //   .then((res)=>{
        //     console.log(2,res)
        //     if(!res.success){
        //       return
        //     }

        //     const accessToken = res.token //result.getIdToken().getJwtToken();

        //     // console.log(accessToken)
        //     //set redux auth state
        //     dispatch(setAccessToken(accessToken))

        //     //nav to home page
        //     navigate('/')     
        //   })

  
    
        var authenticationData = {
            Username : username,
            Password : password,
        };
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        var poolData = { UserPoolId : 'us-east-2_M3YpUH1ko',
            ClientId : '2h4muccfnhksd8n7a869qvp0fd'
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username : username,
            Pool : userPool
        };
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                var accessToken = result.getIdToken().getJwtToken();

                //set redux auth state
                dispatch(setAccessToken(accessToken))
                dispatch(setUser(username))

                //nav to home page
                navigate('/')                
            },
            newPasswordRequired: function(userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                delete userAttributes.email;

                // update name
                var name = prompt('Please enter a name associated with the user' ,'');
                userAttributes.name = name;

                // update password
                var newPassword = prompt('Please enter a new password ' ,'');
                cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                    onSuccess: function(result) {
                        // User authentication was successful
                        var accessToken = result.getIdToken().getJwtToken();

                        //set redux auth state
                        dispatch(setAccessToken(accessToken))
                        dispatch(setUser(username))

                        //nav to home page
                        navigate('/')
                    },
                    onFailure: function(err) {
                        alert(err);
                    }
                });
            },
            onFailure: function(err) {
                alert(err);
            },

        });
    }

    return(
      <div className='login-page-container'>
        <div className='login-container'>
          <form className='login-form' onSubmit={handleSignIn}>
            <label className='login-label'>Username</label>
            {/* <br/> */}
            <input className='login-input' id='username' type="text" />

            <br/>
            <br/>

            <label className='login-label'>Password</label>
            {/* <br/> */}
            <input className='login-input' id='password' type="password" />

            <br/>
            <br/>

            <div>
              <button className="login-button" type="submit">Login</button>
            </div>
          </form>
        </div>

        <NightSky />
      </div>
    )
}