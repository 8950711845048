import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { watchlistsAddWatchlist } from '../../../lib/aws_api';

import { setWatchlistData } from '../../../redux/slices/watchlistDataSlice'

import '../../../css/components/pages/Watchlist/AddWatchlistModal.css'
import TagInput from '../../common/tags/TagInput';

export default function AddWatchlistModal(props) {
    const dispatch = useDispatch()
    let accessToken = useSelector(state => state.accessToken.value)
    let user = useSelector(state => state.user.value)

    const [tags, setTags] = useState([]);

    async function handleSubmit(e){
        e.preventDefault()
        props.loadingFunc(true)

        //parse form data from event
        let list_name = e.target.elements.list_name.value

        let watchlist_data = await watchlistsAddWatchlist(user,accessToken,list_name)

        // let watchlist_data = result.data.watchlist_data
        dispatch(setWatchlistData(watchlist_data))

        props.setOpenAddWatchlistModal(false)
        props.loadingFunc(false)
    }

    if(!props.open){
        return null
    }

    return (
        <div className='add-watchlist-modal'>
            <h2>Add Watchlist</h2>
            <form className='add-watchlist-form' onSubmit={handleSubmit}>
                <div><label>Watchlist Name: </label><input type='text' name='list_name' placeholder='Name'/></div>
                {/* <div><label>Asset Type: </label><input type='text' name='asset_type' placeholder='asset_type'/></div> */}

                {/* <TagInput onTagsChange={(newTags) => setTags(newTags)} /> */}

                <button type='submit'>Submit</button>
            </form>
        </div>
    )
}
