import React, { useEffect, useState } from 'react'

import styles from '../../../css/components/pages/Watchlist/AssetDetailsContainer.module.css'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { abbreviateNumber } from '../../../lib/format'

import YearRangeChart from '../../../components/charts/YearRangeChart';

import Tag from '../../../components/common/tags/Tag';



export default function AssetDetailsContainer(props) {
    let watchlist = props.watchlist
    let selected_ticker = props.selected_ticker

    return (
        <div className={styles.selected_asset_details_container}>
            {selected_ticker ?
                <>
                    <DeleteIcon className={styles.delete_button} onClick={() => props.setOpenDeleteAssetModal(!props.openDeleteAssetModal)}/>
                    <EditIcon className={styles.edit_button} onClick={() => props.setOpenEditWatchlistModal(!props.openEditWatchlistModal)}/>

                    <h2>{selected_ticker}</h2>

                    <h3>Price: {watchlist[selected_ticker].price}</h3>
                    { watchlist[selected_ticker].scraped_info ?
                        <div className={styles.asset_info_container}>
                            <div className={styles.info_container_left_half}>
                                <h5>Earnings: ${watchlist[selected_ticker].scraped_info.yearly_earnings_data ? abbreviateNumber(JSON.parse(watchlist[selected_ticker].scraped_info.yearly_earnings_data).earnings[0],3) : null}</h5>
                                <h5>PE Ratio: {watchlist[selected_ticker].scraped_info.pe_ratio}</h5>
                                <h5>Market Cap: {abbreviateNumber(watchlist[selected_ticker].scraped_info.market_cap,3)}</h5>
                                <h5>Dividend (% Price): {watchlist[selected_ticker].scraped_info.dividend} ({((watchlist[selected_ticker].scraped_info.dividend/watchlist[selected_ticker].price)*100).toFixed(2)}%)</h5>
                                <h5>ROA: {watchlist[selected_ticker].scraped_info.roa}</h5>
                                <h5 className={styles.tags_label}>Tags:</h5>
                                <div className={styles.tags_container}>
                                {watchlist[selected_ticker].tags && watchlist[selected_ticker].tags.map((tag) => {
                                    return (
                                        <Tag text={tag}/>
                                    )
                                })}
                                </div>
                            </div>
                            <div className={styles.info_container_right_half}>
                                {/* <h5>52 Range: {watchlist[selected_ticker].scraped_info['52_range'] ? watchlist[selected_ticker].scraped_info['52_range'][0] : null} - {watchlist[selected_ticker].scraped_info['52_range'] ? watchlist[selected_ticker].scraped_info['52_range'][1] : null }</h5> */}
                                {/* <input class="slider" type="range" :min="0" :max="100" :value="stock.price"/> */}
                                {/* {watchlist[selected_ticker].scraped_info['52_range'] ? <FiftyTwoWeekRangeChart price={watchlist[selected_ticker].price} year_range={watchlist[selected_ticker].scraped_info['52_range']}/>  : null } */}

                                <h5>Year Range: {watchlist[selected_ticker].scraped_info.year_range[0]} - {watchlist[selected_ticker].scraped_info.year_range[1]}</h5>
                                <YearRangeChart price={watchlist[selected_ticker].price} year_range={watchlist[selected_ticker].scraped_info.year_range} />
                            </div>
                        </div>
                    :
                        <h4>Asset not yet scraped</h4>
                    }
                </>
            : <p>Select Asset You Will</p>}
        </div>
    )
}
