import React, { useState, useEffect, useRef } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { resolvePath, useNavigate } from 'react-router-dom';
// import { setAssetData } from '../redux/actions'
import { setAssetData } from '../redux/slices/assetDataSlice'
import { setTransactionData } from '../redux/slices/transactionDataSlice'
import { setTotalValueData } from '../redux/slices/totalValueDataSlice'
import { setWatchlistData } from '../redux/slices/watchlistDataSlice';

import { getStockData, getTransactions, getTotalValue, watchlistsGetAssets } from '../lib/aws_api';


import CardDeck from '../components/CardDeck'
import SummaryBanner from '../components/SummaryBanner'
import TotalValueChart from '../components/charts/TotalValueChart';
import AssetPieChart from '../components/charts/AssetPieChart';
import AssetTypePieChart from '../components/charts/AssetTypePieChart';
import IndustryPieChart from '../components/charts/IndustryPieChart';
import SectorPieChart from '../components/charts/SectorPieChart';

import '../css/Home.css'
import NightSky from '../components/common/NightSky';



export default function Home(){
    let accessToken = useSelector(state => state.accessToken.value)
    let assetData = useSelector(state => state.assetData.value)
    let totalValueData = useSelector(state => state.totalValueData.value)
    let userName = useSelector(state => state.user.value)
    let chartContainer = useRef(null)
    let assetTypePieChartContainer = useRef(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const [stockDataStr, setStockDataStr] = useState('{}')
    const [stockData, setStockData] = useState(assetData)


    //TODO maybe put in redux?
    // const [totalValueDataState, setTotalValueDataState] = useState({})

    const [chartContainerHeight, setChartContainerHeight] = useState(0)
    const [chartContainerWidth, setChartContainerWidth] = useState(0)
    const [assetTypePieChartContainerWidth, setAssetTypePieChartContainerWidth] = useState(0)

    function updateDimensions(){
        setChartContainerHeight(chartContainer.current.clientHeight)
        setChartContainerWidth(chartContainer.current.clientWidth)
        setAssetTypePieChartContainerWidth(assetTypePieChartContainer.current.clientWidth)
    }

    useEffect(() => {
        updateDimensions()
        //add event listener for resize
        window.addEventListener("resize", updateDimensions);
        // cleanup
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);



    useEffect(() => {
        //check if logged in upon initialization
        if(accessToken === ''){
            navigate('/login')
        }
        else{
            //TODO make this not re hit API when you switch back to home page
            //TODO i think first if may be redundant
            if(Object.keys(stockData).length === 0) {
                // console.log('HITTING API')
                getStockData(userName,accessToken)
                    .then((res)=>{
                        setStockData(res)
                        dispatch(setAssetData(res))
                })
                getTransactions(userName,accessToken)
                    .then((res)=>{
                        dispatch(setTransactionData(res))
                })
                getTotalValue(userName,accessToken)
                    .then((res)=>{
                        let total_value_data = res
                        dispatch(setTotalValueData(total_value_data))
                })
                watchlistsGetAssets(userName,accessToken)
                    .then((res)=>{
                        let watchlist = res
                        dispatch(setWatchlistData(watchlist))
                })
            }
        }
    },[])

    return (
        <div className="home-container">
            {/* <h1>Investing App</h1> */}

            <br/>

            <SummaryBanner stockData={stockData}/>

            <br/>

            <div ref={assetTypePieChartContainer} className='pie-chart-padding-container'>
                <div className='all-pie-chart-container'>
                    <div className="pie-chart-container">
                        <label className='pie-chart-title'>Asset Types</label>
                        <AssetTypePieChart stockData={stockData} parentWidth={assetTypePieChartContainerWidth} />
                    </div>
                    <div className="pie-chart-container">
                        <label className='pie-chart-title'>Assets</label>
                        <AssetPieChart stockData={stockData} parentWidth={assetTypePieChartContainerWidth} />
                    </div>
                </div>
                <div className='all-pie-chart-container'>

                    <div className="pie-chart-container">
                        <label className='pie-chart-title'>Industries</label>
                        <IndustryPieChart stockData={stockData} parentWidth={assetTypePieChartContainerWidth} />
                    </div>
                    <div className="pie-chart-container">
                        <label className='pie-chart-title'>Sectors</label>
                        <SectorPieChart stockData={stockData} parentWidth={assetTypePieChartContainerWidth} />
                    </div>

                </div>
            </div>

            <div className='chart-padding-container'>
                <div ref={chartContainer} className='chart-container'>
                    <TotalValueChart totalValueData={totalValueData} parentHeight={chartContainerHeight} parentWidth={chartContainerWidth} />
                </div>
            </div>

            <br/>
            <br/>

            <CardDeck stockData={stockData}/>

            <br/>

            <NightSky/>
        </div>
    )
}