import '../../css/components/common/YearlyDataTable.css'
import { abbreviateNumber } from '../../lib/format'

export default function YearlyDataTable(props) {
    let stock = props.stock

    if(!stock.scraped_info || !stock.scraped_info.yearly_earnings_data){
        return <div className='yearly-data-table'></div>
    }

    let yearly_earnings_data = JSON.parse(stock.scraped_info.yearly_earnings_data)

    return (                    
        <div className='yearly-data-table'>
            {(stock.asset_type == 'Equity' || stock.asset_type == 'equity') ?
                <>
                <div className="yearly-data-table-col">
                    <h5>Date</h5>
                    <h5>P/EPS</h5>
                    <h5>EPS</h5>
                    <h5>Earnings</h5>
                    <h5>Shares Outstanding</h5>
                </div>
                {Object.keys(yearly_earnings_data['date']).map((row_index) => {
                    return (
                        <div className="yearly-data-table-col">
                            { yearly_earnings_data.date[row_index] == 'TTM' ?
                            <>
                                <h5>{yearly_earnings_data.date[row_index]}</h5>
                                <h5>{(stock.price/(yearly_earnings_data.earnings[row_index]/stock.scraped_info.shares_outstanding)).toFixed(2)}</h5>
                                <h5>{(yearly_earnings_data.earnings[row_index]/stock.scraped_info.shares_outstanding).toFixed(2)}</h5>
                                <h5>${yearly_earnings_data.earnings[row_index] == '-' ? yearly_earnings_data.earnings[row_index] : abbreviateNumber(yearly_earnings_data.earnings[row_index],2)}</h5>
                                <h5>{abbreviateNumber(stock.scraped_info.shares_outstanding,2)}</h5>
                            </>
                            :
                            <>
                                <h5>{yearly_earnings_data.date[row_index]}</h5>
                                <h5>{(yearly_earnings_data.earnings[row_index] == '-' || yearly_earnings_data.shares_outstanding[row_index] == '-') ? '-' : (stock.price/(yearly_earnings_data.earnings[row_index]/yearly_earnings_data.shares_outstanding[row_index])).toFixed(2)}</h5>
                                <h5>{(yearly_earnings_data.earnings[row_index] == '-' || yearly_earnings_data.shares_outstanding[row_index] == '-') ? '-' : (yearly_earnings_data.earnings[row_index]/yearly_earnings_data.shares_outstanding[row_index]).toFixed(2)}</h5>
                                <h5>${yearly_earnings_data.earnings[row_index] == '-' ? yearly_earnings_data.earnings[row_index] : abbreviateNumber(yearly_earnings_data.earnings[row_index],2)}</h5>
                                <h5>{yearly_earnings_data.shares_outstanding[row_index] == '-' ? yearly_earnings_data.shares_outstanding[row_index] : abbreviateNumber(yearly_earnings_data.shares_outstanding[row_index],2)}</h5>
                            </>
                            }
                        </div>
                    )
                })}
                </>
                :
                <div className='yearly-data-table'></div>
            }
        </div>
    )
}