import React, { useState, useEffect } from 'react'
import * as d3 from 'd3';


export default function YearRangeChart(props) {
    
    useEffect(() => {
        d3.selectAll("#yearRangeChart > *").remove();

        console.log("YearRangeChart useEffect",props.parentWidth)

        let year_range = props.year_range
        let price = props.price

        // var screenWidth = document.documentElement.clientWidth
        var screenWidth = props.parentWidth

        // set the dimensions and margins of the graph
        // var width = screenWidth
        var width = 200
        var height = width/10
        var margin = 20
        let bottom_margin = 15
        let top_margin = 5

        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        const radius = Math.min(width, height) / 2 - margin;

        // append the svg object to the div called 'assetPieChart'
        const svg = d3.select("#yearRangeChart")
        .append("svg")
            .attr("width", width)
            .attr("height", height + bottom_margin)
        
        let x_func = d3.scaleLinear()
            .domain(year_range)
            .range([0, width])
            .interpolate(d3.interpolateRound);

        svg.append("rect")
            .attr("x", x_func(year_range[0]))
            .attr("y", (height * 0.1) + top_margin)
            .attr("width", x_func(price) - x_func(year_range[0]))
            .attr("height", (height * 0.8))
            .attr("fill", "steelblue")

        let line1 = d3.line()
            .x(d => 0)
            .y(d => d + top_margin)
            
        let line2 = d3.line()
            .x(d => width)
            .y(d => d + top_margin)
        
        svg.append("path")
            .attr("d", line1([0, height]))
            .attr("stroke", "black")
            .attr("stroke-width", ".25rem")
            .attr("fill", "none")

        svg.append("path")
            .attr("d", line2([0, height]))
            .attr("stroke", "black")
            .attr("stroke-width", ".25rem")
            .attr("fill", "none")
            
        svg.append("text")
            .attr("x", x_func(year_range[0]))
            .attr("y", (height * 0.9) + top_margin)
            .text(year_range[0])
            .attr("font-size", ".5rem")
            .attr("fill", "black")
            .attr("text-anchor", "start")
            .attr("dy",".6rem")

        svg.append("text")
            .attr("x", x_func(year_range[1]))
            .attr("y", (height * 0.9) + top_margin)
            .text(year_range[1])
            .attr("font-size", ".5rem")
            .attr("fill", "black")
            .attr("text-anchor", "end")
            .attr("dy",".6rem")

        svg.append("text")
            .attr("x", x_func(price))
            .attr("y", (height * 0.1) + top_margin - 1)
            .text(price)
            .attr("font-size", ".5rem")
            .attr("fill", "black")
            .attr("text-anchor", "middle")

    }, [props.price,props.year_range,props.parentWidth])

    return (
        <div id="yearRangeChart"></div>
    )
}