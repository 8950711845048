import React, { useState, useEffect } from 'react'
import * as d3 from 'd3';


export default function TotalValueChart(props) {

    useEffect(() => {
        let total_value_data = props.totalValueData

        if(Object.keys(total_value_data).length === 0){
            return
        }

        d3.selectAll("#totalValueLineChart > *").remove();

        //add attribute to total_value_data.records

        let record_list = []

        total_value_data.records.forEach((record) => {
            // record.data = new Date(record.date)
            let record_obj = {...record}
            record_obj.date = new Date(record.date)
            record_list.push(record_obj)
        })

        //convert date to int
        // total_value_data.records.forEach((record) => {
        //     record.data = new Date(record.date)
        // })

        // var screenWidth = document.documentElement.clientWidth
        var screenWidth = props.parentWidth
        // var screenHeight = document.documentElement.clientHeight
        var screenHeight = props.parentHeight

        // set the dimensions and margins of the graph
        const margin = {top: 10, right: 30, bottom: 30, left: 60},
            width = screenWidth - margin.left - margin.right,
            // height = (screenHeight * .3) - margin.top - margin.bottom;
            height = screenHeight - margin.top - margin.bottom;

        // append the svg object to the body of the page
        const svg = d3.select("#totalValueLineChart")
        .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

        // Add X axis --> it is a date format
        const x = d3.scaleTime()
            .domain(d3.extent(record_list, function(d) { return d.date; }))
            .range([ 0, width ]);
        svg.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b%e")));

        // Add Y axis
        const y = d3.scaleLinear()
            .domain([0, d3.max(record_list, function(d) { return +d.total_value; })])
            .range([ height, 0 ])
            .nice();
        svg.append("g")
            // .call(d3.axisLeft(y));
            .call(d3.axisLeft(y).tickSize(-window.innerWidth));


        // Add the cashe line
        svg.append("path")
            .datum(record_list)
            .attr("fill", "none")
            .attr("stroke", "green")
            .attr("stroke-width", 2)
            .attr("d", d3.line()
                .x(function(d) { return x(d.date) })
                .y(function(d) { 
                    return y(d.total_value) 
                })
            );

        // Add the invested line
        svg.append("path")
            .datum(record_list)
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 2)
            .attr("d", d3.line()
                .x(function(d) { return x(d.date) })
                .y(function(d) { 
                    return y(d.total_value - d.cash) 
                })
            );

        // Add the cost line
        svg.append("path")
            .datum(record_list)
            .attr("fill", "none")
            .attr("stroke", "red")
            .attr("stroke-width", 2)
            .attr("d", d3.line()
                .x(function(d) { return x(d.date) })
                .y(function(d) { 
                    return y(d.total_cost) 
                })
            );
        }, [props.totalValueData,props.parentHeight,props.parentWidth])

    return (
        // <div className="total-value-chart">
        //     <Line data={chartData} options={chartOptions} />
        // </div>
        <div id="totalValueLineChart" className="totalValueLineChart">
        </div>
    )


}