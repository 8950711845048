import React, { useState, useEffect } from 'react'
import * as d3 from 'd3';


export default function TotalValueChart(props) {
    
    // function getAssetPercents(stockData){
    //     //get asset value sum, then find percentages
    //     let sum = 0
    //     for (let i =0; i < stockData.length;i++){
    //         // console.log(this.stocks[i])
    //         let stock = stockData[i]
    //         console.log('stock',stock)
    //         if(stock.asset_type !== 'cash'){
    //             sum = sum + (stock.price*stock.shares)
    //         }
    //     }
    //     console.log('sum',sum)

    //     let asset_percents = {}
    //     for (let i =0; i < stockData.length;i++){
    //         let stock = stockData[i]
    //         if(stock.asset_type !== 'cash'){

    //             let percent = ((stock.price*stock.shares) / sum) * 100
    //             //if it exists concate percents
    //             if(Object.keys(asset_percents).includes(stock.ticker)){
    //                 asset_percents[stock.ticker] = asset_percents[stock.ticker] + percent
    //             }
    //             else{
    //                 asset_percents[stock.ticker] = percent
    //             }
    //         }
    //     }

    //     return asset_percents
    // }

    function getAssetPercents(stockData){
        //get asset value sum, then find percentages
        let keys = Object.keys(stockData)
        let sum = 0.0
        for (let i =0; i < keys.length;i++){
            let stock = stockData[keys[i]]

            if(stock.asset_type !== 'cash'){
                    
                // console.log('sum',sum)

                // console.log('stock',stock,stock.price*stock.shares)

                sum = sum + (stock.price*stock.shares)
                // console.log('sum',sum)
            }

        }



        let asset_percents = {}
        for (let i =0; i < keys.length;i++){
            let stock = stockData[keys[i]]
            if(stock.asset_type !== 'cash'){

                // console.log('stock',stock)
                // sum = sum + (stock.price*stock.shares)
                let percent = ((stock.price*stock.shares) / sum) * 100
                // console.log('percent',percent)
                //if it exists concate percents
                if(Object.keys(asset_percents).includes(stock.ticker)){
                    asset_percents[stock.ticker] = asset_percents[stock.ticker] + percent
                }
                else{
                    asset_percents[stock.ticker] = percent
                }
            }
        }

        return asset_percents
    }

    useEffect(() => {
        if(Object.keys(props.stockData).length === 0){
            return
        }

        d3.selectAll("#assetPieChart > *").remove();

        let asset_percents = getAssetPercents(props.stockData)

        // var screenWidth = document.documentElement.clientWidth
        var screenWidth = props.parentWidth

        // set the dimensions and margins of the graph
        // var width = screenWidth
        // var height = screenWidth
        var width = props.parentWidth / 2.5
        var height = props.parentWidth / 2.5
        var margin = 20

        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        const radius = Math.min(width, height) / 2 - margin;

        // append the svg object to the div called 'assetPieChart'
        const svg = d3.select("#assetPieChart")
            .append("svg")
                .attr("width", width)
                .attr("height", height)
            .append("g")
                .attr("transform", `translate(${width/2}, ${height/2})`);

        const data = asset_percents

        // set the color scale
        const color = d3.scaleOrdinal(d3.schemeCategory10)
            // .range(d3. + ["#98abc5", "#8a89a6", "#7b6888", "#6b486b", "#a05d56"])
                

        // Compute the position of each group on the pie:
        const pie = d3.pie()
            .value(function(d) {return d[1]})
        const data_ready = pie(Object.entries(data))

        // shape helper to build arcs:
        const arcGenerator = d3.arc()
            .innerRadius(0)
            .outerRadius(radius)

        // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
        svg
            .selectAll('whatever')
            .data(data_ready)
            .join('path')
                .attr('d', arcGenerator)
                .attr('fill', function(d){ return(color(d.data[1])) })
                .attr("stroke", "black")
                .style("stroke-width", "2px")
                .style("opacity", 0.7)
        // Now add the annotation. Use the centroid method to get the best coordinates
        svg
            .selectAll('mySlices')
            .data(data_ready)
            .join('text')
                .text(function(d){ return d.data[0]})
                .attr("transform", function(d) { return `translate(${arcGenerator.centroid(d)})`})
                .style("text-anchor", "middle")
                .style("font-size", 10)

    }, [props.stockData,props.parentWidth])

    return (
        <div id="assetPieChart"></div>
    )
}