import React, { useState } from "react";

import Tag from "./Tag";

function TagInput(props) {
  const [tags, setTags] = useState(props.initialTags || []);
  const [inputValue, setInputValue] = useState("");

  function handleAddTag() {
    const value = inputValue.trim();
    if (value) {
      setTags([...tags, value]);
      setInputValue("");
      props.onTagsChange([...tags, value]); // Call parent function with updated tags array
    }
  }

  function handleRemoveTag(index) {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
    props.onTagsChange(newTags); // Call parent function with updated tags array
  }

  function handleChange(event) {
    setInputValue(event.target.value);
  }

  return (
    <div>
      <ul>
        {tags.map((tag, index) => (
          <li key={index}>
            {/* {tag} */}
            {/* <button type='button' onClick={() => handleRemoveTag(index)}>x</button> */}
            <Tag text={tag} deleteFunc={() => handleRemoveTag(index)}/>
          </li>
        ))}
      </ul>
      <div>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder="Add tag"
        />
        <button type='button' onClick={handleAddTag}>+</button>
      </div>
    </div>
  );
}

export default TagInput;
