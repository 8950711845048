import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        value: '',
    },
    reducers: {
        setUser: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setUser } = userSlice.actions;

// export const selectAccessToken = state => state.accessToken.value;

export default userSlice.reducer;