import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import '../css/components/CardDeck.css'

export default function CardDeck(props) {
    // let aggregateFlag = props.aggregateFlag

    const [stockData, setStockData] = useState(props.stockData)
    const [aggregateStockData, setAggregateStockData] = useState({})
    const [aggregateFlag, setAggregateFlag] = useState(true)
    const navigate = useNavigate();



    useEffect(() => {    
        let {...assetData} = props.stockData
    
        let aggregateData = {}
        for(const key in assetData){
            if(key !== "CASH"){
            const ticker = assetData[key].ticker
            if(ticker in aggregateData){
                //average new purchase price
                aggregateData[ticker].purchase_price = (aggregateData[ticker].purchase_price * aggregateData[ticker].shares + assetData[key].purchase_price * assetData[key].shares) / (aggregateData[ticker].shares + assetData[key].shares)
                //sum new amount of shares
                aggregateData[ticker].shares =  aggregateData[ticker].shares + assetData[key].shares
                //sum new amount of fees
                aggregateData[ticker].purchase_fee =  aggregateData[ticker].purchase_fee + assetData[key].purchase_fee
            }
            else{
                aggregateData[ticker] = {}
                Object.assign(aggregateData[ticker],assetData[key])
            }
            }
        }

        setStockData(props.stockData)
        setAggregateStockData(aggregateData)

    },[props.stockData])

    // useEffect(() => {
    //     aggregateFlag = props.aggregateFlag
    // },[props.aggregateFlag])

    function handleAggregateCheckbox(e){
        setAggregateFlag(!aggregateFlag)
    }

    return (
        <div className="card-deck">
            <div className="card-deck-menu">
                <label>Aggregate Assets</label>
                {/* <ProSwitch /> */}
                <input type="checkbox" onChange={handleAggregateCheckbox} checked={aggregateFlag}/>
            </div>

            {Object.entries((aggregateFlag ? aggregateStockData : stockData)).map((asset,index) => {
                if(asset[0] === 'CASH'){
                    return
                }

                let {ticker, price, shares, purchase_price, purchase_date} = asset[1]

                return (
                    <div key={index} className='card' onClick={() => navigate(`/assetdetails?ticker=${ticker}`)}>
                        <div className='card-header'>
                            <h3 className='card-ticker'>{ticker}</h3>
                            <div className='card-price'>
                                <span>Price: ${price.toFixed(2)}</span>
                                <span className={((price/purchase_price)*100) < 100 ? 'card-price-percent-change-down' : 'card-price-percent-change-up'}> {((price/purchase_price)*100).toFixed(0)}%</span>
                            </div>
                        </div>
                        <p className='card-value'>Value: {(price * shares).toFixed(2)} </p>
                        <p className='card-shares'>Shares: {shares}</p>
                        <p className='card-cost'>Cost: {(purchase_price * shares).toFixed(2)} </p>
                        <p className='card-purchase-price'>Purchase Price: {purchase_price.toFixed(2)}</p>
                        <p className='card-purchase-date'>Purchase Date: {purchase_date}</p>
                    </div>
                )
            })}
        </div>
    )
}