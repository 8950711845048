import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { watchlistsDeleteWatchlist } from '../../../lib/aws_api';

import { setWatchlistData } from '../../../redux/slices/watchlistDataSlice'

import '../../../css/components/pages/Watchlist/DeleteWatchlistModal.css'
import TagInput from '../../common/tags/TagInput';

export default function DeleteWatchlistModal(props) {
    const dispatch = useDispatch()
    let accessToken = useSelector(state => state.accessToken.value)
    let user = useSelector(state => state.user.value)

    let watchlist_data = props.watchlist_data

    let selectedWatchlist = props.selectedWatchlist

    async function handleSubmit(e){
        e.preventDefault()
        props.loadingFunc(true)

        // check that watchlist has no assets, ie is empty
        Object.keys(props.watchlist_data).map((asset_key) => {
            // if in list of watchlists
            if(props.watchlist_data[asset_key].watchlists.includes(selectedWatchlist)){
                // alert error
                alert("Watchlist is not empty, please remove all assets before deleting.")
                props.loadingFunc(false)
                return
            }
        })

        let watchlist_data = await watchlistsDeleteWatchlist(user,accessToken,selectedWatchlist)

        // let watchlist_data = result.data.watchlist_data
        dispatch(setWatchlistData(watchlist_data))

        props.setOpenDeleteWatchlistModal(false)
        props.loadingFunc(false)
    }

    if(!props.open){
        return null
    }

    return (
        <div className='add-watchlist-modal'>
            <h2>Delete Watchlist "{selectedWatchlist}" ?</h2>
            <form className='delete-watchlist-form' onSubmit={handleSubmit}>
                <button type='submit'>Submit</button>
            </form>
        </div>
    )
}
