import React, { useState, useEffect, useRef } from 'react'
// import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'


import styles from '../css/pages/Watchlist.module.css'


import LoadingSpinner from '../components/common/LoadingSpinner';
import FilterComponent from '../components/common/FilterComponent';
import AddAssetModal from '../components/pages/Watchlist/AddAssetModal';
import AddWatchlistModal from '../components/pages/Watchlist/AddWatchlistModal';
import DeleteAssetModal from '../components/pages/Watchlist/DeleteAssetModal';
import DeleteWatchlistModal from '../components/pages/Watchlist/DeleteWatchlistModal';
import EditWatchlistModal from '../components/pages/Watchlist/EditWatchlistModal';
import WatchlistTable from '../components/pages/Watchlist/WatchlistTable';
import AssetDetailsContainer from '../components/pages/Watchlist/AssetDetailsContainer';



import YearlyDataTable from '../components/common/YearlyDataTable';
import DividendBarChart from '../components/charts/DividendBarChart';
import WatchlistsListMenu from '../components/pages/Watchlist/WatchlistsListMenu';
import NightSky from '../components/common/NightSky';

export default function Watchlist(){
    let watchlists_data = useSelector(state => state.watchlistData.value)
    let watchlist = watchlists_data.stocks
    let watchlists_lists = watchlists_data.watchlists

    const [displayedWatchlist, setDisplayedWatchlist] = useState(watchlist)


    const [openAddAssetModal, setOpenAddAssetModal] = useState(false)
    const [openAddWatchlistModal, setOpenAddWatchlistModal] = useState(false)
    const [openDeleteAssetModal, setOpenDeleteAssetModal] = useState(false)
    const [openDeleteWatchlistModal, setOpenDeleteWatchlistModal] = useState(false)
    const [openEditWatchlistModal, setOpenEditWatchlistModal] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [innerNav, setInnerNav] = useState('earnings')

    let dividendChartContainer = useRef(null)

    const [dividendChartContainerHeight, setDividendChartContainerHeight] = useState(0)
    const [dividendChartContainerWidth, setDividendChartContainerWidth] = useState(0)
    // const [assetTypePieChartContainerWidth, setAssetTypePieChartContainerWidth] = useState(0)

    const [selectedWatchlist, setSelectedWatchlist] = useState('All')
    const [selected_ticker, setSelectedTicker] = useState(null)

    function updateDimensions(){
        if(dividendChartContainer.current){
            setDividendChartContainerHeight(dividendChartContainer.current.clientHeight)
            setDividendChartContainerWidth(dividendChartContainer.current.clientWidth)
        }
        // setAssetTypePieChartContainerWidth(assetTypePieChartContainer.current.clientWidth)
    }

    useEffect(() => {
        updateDimensions()
        //add event listener for resize
        window.addEventListener("resize", updateDimensions);
        // cleanup
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        // setDisplayedWatchlist(watchlists_data.stocks);
        if(selectedWatchlist === 'All'){
            setDisplayedWatchlist(watchlist)
            return
        }

        let new_displayed = {}
        for(let key in watchlist){
            if(watchlist[key].watchlists.includes(selectedWatchlist)){
                new_displayed[key] = watchlist[key]
            }
        }
        setDisplayedWatchlist(new_displayed)
    }, [watchlists_data]); // Dependency array

    function handleRowCheckbox(event){
        if(event.target.checked === true){
            //uncheck all other checkboxes
            let row_number = Number(event.target.name)

            let checkboxes = document.getElementsByClassName('watchlist-row-checkbox')

            for(let i = 0; i < checkboxes.length; i++){
                if(i !== row_number){
                    // let checkbox = document.getElementsByName(i)
                    let checkbox = checkboxes[i]

                    checkbox.checked = false
                }
            }

            setSelectedTicker(event.target.id)
        }
        else{
            setSelectedTicker(null)
        }
    }

    const handleFilter = (filtered) => {
        console.log("made it", filtered)
        setDisplayedWatchlist(filtered)

        // displayedWatchlist = filtered
    }

    // useEffect(() => {
    //     // updateDimensions()
    //     //add event listener for resize
    //     window.addEventListener("resize", updateDimensions);
    //     // cleanup
    //     return () => window.removeEventListener("resize", updateDimensions);
    // }, []);


    // useEffect(() => {
    //     if(chartContainer.current){
    //         setYearRangeContainerWidth(chartContainer.current.clientWidth)
    //     }
    // }, [chartContainer.current])

    const handleOpenAddWatchlistModal = () => {
        setOpenAddWatchlistModal(!openAddWatchlistModal)
    }

    const handleOpenDeleteWatchlistModal = () => {
        setOpenDeleteWatchlistModal(!openDeleteWatchlistModal)
    }

    const handleListSelect = (list_name) => {
        if(list_name === 'All'){
            setDisplayedWatchlist(watchlist)
            setSelectedWatchlist('All')
            return
        }

        let new_displayed = {}
        for(let key in watchlist){
            if(watchlist[key].watchlists.includes(list_name)){
                new_displayed[key] = watchlist[key]
            }
        }
        setDisplayedWatchlist(new_displayed)
        setSelectedWatchlist(list_name)
        setSelectedTicker(null)
        let checkboxes = document.getElementsByClassName('watchlist-row-checkbox')
        for(let i = 0; i < checkboxes.length; i++){
            checkboxes[i].checked = false
        }

    }

    return (
        <div className={styles.watchlist_page_container}>
            {isLoading ? <LoadingSpinner /> :
            <>
                {/* <h1>Watchlist</h1> */}
                <WatchlistsListMenu watchlists_data={watchlists_data} handleListSelect={handleListSelect} setDisplayedWatchlist={setDisplayedWatchlist} handleOpenAddWatchlistModal={handleOpenAddWatchlistModal} handleOpenDeleteWatchlistModal={handleOpenDeleteWatchlistModal} selectedItem={selectedWatchlist}/>
                <div className={styles.left_menu_body_container}>
                </div>

                <div className={styles.left_side_container}>
                    <div className={styles.watchlist_table_controls}>
                        <FilterComponent data={displayedWatchlist} onFilter={handleFilter}/>
                        <button className={styles.add_asset_button} disabled={selectedWatchlist === "All"} onClick={() => setOpenAddAssetModal(!openAddAssetModal)}>+</button>
                    </div>

                    <WatchlistTable displayedWatchlist={displayedWatchlist} onRowCheckbox={handleRowCheckbox} />
                </div>

                <div className={styles.right_side_container}>
                    <AssetDetailsContainer watchlist={watchlist } selected_ticker={selected_ticker} setOpenDeleteAssetModal={setOpenDeleteAssetModal} setOpenEditWatchlistModal={setOpenEditWatchlistModal} openDeleteWatchlistModal={openDeleteWatchlistModal} openEditWatchlistModal={openEditWatchlistModal}/>
                </div>

                <div className={styles.left_menu_body_container}>
                </div>

                <div className={styles.bottom_section_container}>
                    { selected_ticker && (watchlist[selected_ticker].asset_type === 'equity' || selected_ticker && watchlist[selected_ticker].asset_type === 'etf') ?
                        <>
                            <div className={styles.inner_page_navbar}>
                                    <div className={`${styles.inner_page_navbar_item} ${innerNav === 'earnings' ? 'active' : null}`} onClick={() => setInnerNav('earnings')}>
                                        <h4>Earnings</h4>
                                    </div>
                                    <div className={`${styles.inner_page_navbar_item} ${innerNav === 'dividends' ? 'active' : null}`} onClick={() => setInnerNav('dividends')}>
                                        <h4>Dividends</h4>
                                    </div>
                            </div>



                            {selected_ticker && innerNav === 'earnings' ?
                                <div className={styles.yearly_financial_table_container}>
                                        <YearlyDataTable stock={watchlist[selected_ticker]}/>
                                </div>
                                : null
                            }

                            {selected_ticker && innerNav === 'dividends' ?
                            <>
                                <div className={styles.dividend_table_container}>
                                    {JSON.parse(watchlist[selected_ticker].scraped_info.dividend_data).map((dividend) => {
                                        return (
                                            <div className={styles.dividend_table_row}>
                                                <span className={styles.dividend_year_cell}>{dividend[0]}</span><span className={styles.dividend_amount_cell}>{dividend[1]}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className={styles.dividend_chart_container} ref={dividendChartContainer}>
                                    <DividendBarChart data={JSON.parse(watchlist[selected_ticker].scraped_info.dividend_data)} parentHeight={dividendChartContainerHeight} parentWidth={dividendChartContainerWidth}/>
                                </div>
                            </>
                                : null
                            }
                        </>
                    : <h2>Select Asset You Will</h2>
                    }
                </div>

                <AddAssetModal open={openAddAssetModal} selectedWatchlist={selectedWatchlist} setOpenAddAssetModal={setOpenAddAssetModal} loadingFunc={(state) => setIsLoading(state)} />
                <AddWatchlistModal open={openAddWatchlistModal} setOpenAddWatchlistModal={setOpenAddWatchlistModal} loadingFunc={(state) => setIsLoading(state)}/>
                <DeleteAssetModal open={openDeleteAssetModal} selectedTicker={selected_ticker}  selectedWatchlist={selectedWatchlist} setOpenDeleteAssetModal={setOpenDeleteAssetModal} loadingFunc={(state) => setIsLoading(state)}/>
                <DeleteWatchlistModal open={openDeleteWatchlistModal} watchlist_data={watchlist} setOpenDeleteWatchlistModal={setOpenDeleteWatchlistModal} selectedWatchlist={selectedWatchlist}loadingFunc={(state) => setIsLoading(state)}/>
                <EditWatchlistModal  open={openEditWatchlistModal} loadingFunc={(state) => setIsLoading(state)} watchlist_asset={watchlist[selected_ticker]}/>

                <NightSky />
            </>
            }
        </div>
    )
}