import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { deletePosition } from '../../../lib/aws_api';

import { setAssetData } from '../../../redux/slices/assetDataSlice'

import styles from '../../../css/components/pages/Portfolio/DeletePositionModal.module.css'

export default function DeletePositionModal(props) {
    const dispatch = useDispatch()
    let accessToken = useSelector(state => state.accessToken.value)
    let user = useSelector(state => state.user.value)

    async function handleSubmit(e){
        e.preventDefault()
        props.loadingFunc(true)

        // let watchlist_data = await deletePosition(user,accessToken,props.ticker)
        let asset_data = await deletePosition(user,accessToken,props.asset_key)

        dispatch(setAssetData(asset_data))

        // dispatch(setWatchlistData(watchlist_data))
        props.clearSelectedTicker()


        props.loadingFunc(false)

    }

    if(!props.open){
        return null
    }

    return (
        <div className={styles.delete_position_modal}>
            <h2>Delete Position</h2>
            <h5>Are you sure you want to delete this asset from portfolio?</h5>
            <h5>{props.ticker}</h5>

            {/* <div className='transaction-details'>
                <div><label>Ticker: </label><p>{transaction.data.ticker}</p></div>
                <div><label>Shares: </label><p>{transaction.data.shares}</p></div>
                <div><label>Purchase Date: </label><p>{transaction.data.purchase_date}</p></div>
                <div><label>Purchase Price: </label><p>{transaction.data.purchase_price}</p></div>
                <div><label>Purchase Fee: </label><p>{transaction.data.purchase_fee}</p></div>
                <div><label>Account: </label><p>{transaction.data.account}</p></div>
            </div> */}

            <button onClick={handleSubmit}>Yes</button>
            <button>No</button>

            <br/>
            <br/>
        </div>
    )
}
