import { createSlice } from '@reduxjs/toolkit';

export const assetDataSlice = createSlice({
    name: 'assetData',
    initialState: {
        value: {},
    },
    reducers: {
        setAssetData: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setAssetData } = assetDataSlice.actions;

// export const selectAssetData = state => state.assetData.value;

export default assetDataSlice.reducer;