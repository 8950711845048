import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { watchlistsEditAsset } from '../../../lib/aws_api';

import { setWatchlistData } from '../../../redux/slices/watchlistDataSlice'

import TagInput from '../../common/tags/TagInput';

import '../../../css/components/pages/Watchlist/EditWatchlistModal.css'

export default function EditWatchlistModal(props) {
    const dispatch = useDispatch()
    let accessToken = useSelector(state => state.accessToken.value)
    let user = useSelector(state => state.user.value)

    const [tags, setTags] = useState([]);


    async function handleSubmit(e){
        e.preventDefault()
        props.loadingFunc(true)

        //parse form data from event
        let ticker = e.target.elements.ticker.value
        let asset_type = e.target.elements.asset_type.value
        // let tags = e.target.elements.tags.value

        let asset = {
            ticker,
            asset_type,
            tags
        }

        console.log(tags)

        let watchlist_data = await watchlistsEditAsset(user,accessToken,asset)

        dispatch(setWatchlistData(watchlist_data))

        props.loadingFunc(false)
    }

    if(!props.open){
        return null
    }

    return (
        <div className='edit-watchlist-modal'>
            <h2>Edit Watchlist</h2>
            <form className='edit-watchlist-form' onSubmit={handleSubmit}>
                <div><label>Ticker: </label><input type='text' name='ticker' defaultValue={props.watchlist_asset.ticker}/></div>
                <div><label>Asset Type: </label><input type='text' name='asset_type' defaultValue={props.watchlist_asset.asset_type}/></div>

                <TagInput onTagsChange={(newTags) => setTags(newTags)} initialTags={props.watchlist_asset.tags}/>

                <button type='submit'>Submit</button>
            </form>
        </div>
    )
}
