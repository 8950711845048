import React from "react";
import { Routes, Route } from "react-router-dom";

import './App.css';

import PageLayout from "./components/common/PageLayout";

import Home from './pages/Home'
import Login from './pages/Login'
import AssetDetails from './pages/AssetDetails'
import Portfolio from './pages/Portfolio'
import Transactions from './pages/Transactions'
import Watchlist from "./pages/Watchlist";



function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route element={<PageLayout />} >
          <Route path="/" element={<Home />} />
          <Route path="/assetdetails" element={<AssetDetails />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/watchlist" element={<Watchlist />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
