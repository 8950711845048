import axios from 'axios';

import { AuthenticationDetails,CognitoUserPool,CognitoUser } from 'amazon-cognito-identity-js'


const MAIN_API_URL='https://67p2opm3cj.execute-api.us-east-2.amazonaws.com/prod'


export async function addTransaction(token,user,transaction){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }
  //get stock info from aws main-api
  // let res = await axios.post(`${MAIN_API_URL}/transactions?user=${user}`,{headers: headers})


  // let res = await axios.get(`${MAIN_API_URL}/transactions?user=${user}`,{headers: headers})


  let res = await axios.post(`${MAIN_API_URL}/add_transaction?user=${user}`,transaction,{headers: headers})

  console.log(`res`, res)

  let transaction_json = res.data.transaction_data

  // console.log(`trans json`, transaction_json)

  // let transaction_list = []

  // for(let key in transaction_json){
  //   transaction_list.push(transaction_json[key])
  // }

  return transaction_json
}

export async function createAsset(user,token,asset){
  const headers = {
    Authorization: "Bearer " + token
  }

  let response = await axios.post(`${MAIN_API_URL}/stock_data?user=${user}`, asset, {headers: headers})

  console.log('response', response)
  let asset_json = response.data.asset_data.stocks

  asset_json = await getPrices(asset_json)

  return asset_json
}


export async function deletePosition(user,token,asset_key){
  const headers = {
    Authorization: "Bearer " + token
  }

  let response = await axios.post(`${MAIN_API_URL}/delete_position?user=${user}`, {asset_key: asset_key}, {headers: headers})
  let asset_json = response.data.asset_data.stocks

  console.log('response', response)

  asset_json = await getPrices(asset_json)

  return asset_json
}


export async function deleteTransaction(token,user,row_number){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }
  //get stock info from aws main-api
  // let res = await axios.post(`${MAIN_API_URL}/transactions?user=${user}`,{headers: headers})


  // let res = await axios.get(`${MAIN_API_URL}/transactions?user=${user}`,{headers: headers})


  let res = await axios.post(`${MAIN_API_URL}/delete_transaction?user=${user}`,{row_number},{headers: headers})

  console.log(`res`, res)

  let transaction_json = res.data.transaction_data

  // console.log(`trans json`, transaction_json)

  // let transaction_list = []

  // for(let key in transaction_json){
  //   transaction_list.push(transaction_json[key])
  // }

  return transaction_json
}



export async function editPosition(user,token,asset_key,asset){
  const headers = {
    Authorization: "Bearer " + token
  }

  let response = await axios.post(`${MAIN_API_URL}/edit_position?user=${user}`, {asset_key: asset_key,asset: asset}, {headers: headers})

  console.log('response', response)

  let asset_json = response.data.asset_data.stocks

  asset_json = await getPrices(asset_json)

  return asset_json
}

export async function getPrices(stock_json){
    //get keys of stocks
    let keys = Object.keys(stock_json)

    //filter out cash assets
    let ticker_list = [];
    for (let key of keys){
      if (key != 'CASH'){
        ticker_list.push(stock_json[key]['ticker'])
      }
    }
    //getting unique tickers from list
    let unique_ticker_list = [... new Set(ticker_list)]

    //make query string
    let url_tickers = ''
    for (let tick of unique_ticker_list){
      url_tickers = url_tickers + tick + '%2C'
    }

    // API Call Options
    var options = {
      method: 'GET',
      url: 'https://yfapi.net/v6/finance/quote?region=US&lang=en&symbols=' + url_tickers,
      params: {modules: 'defaultKeyStatistics,assetProfile'},
      headers: {
        'x-api-key': '4PXvqXFA834vLwtIPDkeu6YWcXd2MhaC8UR6YScJ'
      }
    };

    //API Call
    if (unique_ticker_list.length > 0){
      let rez = (await axios.request(options)).data.quoteResponse.result

      // TODO Clean up Loops
      for (let key of keys){
        if (key != 'CASH'){
          for (let item of rez){
            if (item.symbol == stock_json[key]['ticker']){
              stock_json[key].price = item.regularMarketPrice
              stock_json[key].open = item.regularMarketOpen
              stock_json[key].day_change = item.regularMarketPrice - item.regularMarketOpen
              stock_json[key].day_change_percent = ((item.regularMarketPrice - item.regularMarketOpen) / item.regularMarketOpen) * 100
            }
          }
        }
      }

    }
    return stock_json
}

export async function getStockData(user,token){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }

  //get stock info from aws main-api
  let res = await axios.get(`${MAIN_API_URL}/stock_data?user=${user}`,{headers: headers})
  let stock_json = res.data.stock_data.stocks

  //get json keys
  stock_json = await getPrices(stock_json)

  // TODO cleanup list, only return 1
  // let stocks_list = []

  // for(let key in stock_json){
  //   if(key != "CASH"){
      // stocks_list.push(stock_json[key])
  //   }
  // }

  // return [stock_json,stocks_list]
  return stock_json
}

export async function getTotalValue(user,token){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }
  //get stock info from aws main-api
  let res = await axios.get(`${MAIN_API_URL}/total_value?user=${user}`,{headers: headers})
  let total_value = res.data.total_value_data

  // let transaction_list = []

  // for(let key in transaction_json){
  //   transaction_list.push(transaction_json[key])
  // }

  return total_value
}

export async function getTransactions(user,token){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }
  //get stock info from aws main-api
  let res = await axios.get(`${MAIN_API_URL}/transactions?user=${user}`,{headers: headers})
  let transaction_json = res.data.transaction_data.transactions

  // let transaction_list = []

  // for(let key in transaction_json){
  //   transaction_list.push(transaction_json[key])
  // }

  return transaction_json
}

export async function updateAssetNotes(token,user,asset_ticker,notes){
  const headers = {
    Authorization: "Bearer " + token
  }

  let res = await axios.post(`${MAIN_API_URL}/update_asset_notes?user=${user}`,{asset_ticker,notes},{headers: headers})

  console.log(`res`, res)

  return res
}



// ##############################
// #  watchlists functions
// ##############################

export async function watchlistsAddAsset(user,token,asset,list_name){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }

  let response = await axios.post(`${MAIN_API_URL}/watchlists/add_asset?user=${user}`, {asset,list_name}, {headers: headers})
  let watchlist_data = response.data.watchlist_data
  let watchlist_json = response.data.watchlist_data.stocks

  // TODO dont want to call twice
  watchlist_json = await getPrices(watchlist_json)
  watchlist_data.stocks = watchlist_json

  return watchlist_data
}

// TODO this probably doesnt have to call getPrices()
export async function watchlistsAddWatchlist(user,token,list_name){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }

  let response = await axios.post(`${MAIN_API_URL}/watchlists/add_watchlist?user=${user}`, {list_name}, {headers: headers})
  let watchlist_data = response.data.watchlist_data
  let watchlist_json = response.data.watchlist_data.stocks

  watchlist_json = await getPrices(watchlist_json)
  watchlist_data.stocks = watchlist_json

  return watchlist_data
}

export async function watchlistsDeleteAsset(user,token,ticker,list_name){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }

  let response = await axios.post(`${MAIN_API_URL}/watchlists/delete_asset?user=${user}`, {ticker,list_name}, {headers: headers})
  let watchlist_data = response.data.watchlist_data
  let watchlist_json = response.data.watchlist_data.stocks

  watchlist_json = await getPrices(watchlist_json)
  watchlist_data.stocks = watchlist_json

  return watchlist_data
}

export async function watchlistsDeleteWatchlist(user,token,list_name){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }

  let response = await axios.post(`${MAIN_API_URL}/watchlists/delete_watchlist?user=${user}`, {list_name}, {headers: headers})
  let watchlist_data = response.data.watchlist_data
  let watchlist_json = response.data.watchlist_data.stocks

  watchlist_json = await getPrices(watchlist_json)
  watchlist_data.stocks = watchlist_json

  return watchlist_data
}

export async function watchlistsEditAsset(user,token,asset){
  const headers = {
    Authorization: "Bearer " + token
  }

  let response = await axios.post(`${MAIN_API_URL}/watchlists/edit_asset?user=${user}`, {asset: asset}, {headers: headers})

  let watchlist_json = response.data.asset_data.stocks

  watchlist_json = await getPrices(watchlist_json)

  return watchlist_json
}

export async function watchlistsGetAssets(user,token){
  // const params = new URLSearchParams([['user', user]]);

  const headers = {
    Authorization: "Bearer " + token
  }

  let response = await axios.get(`${MAIN_API_URL}/watchlists?user=${user}`, {headers: headers})
  let watchlists_json = response.data.stock_data
  let asset_json = response.data.stock_data.stocks

  // TODO dont want to call twice
  watchlists_json.stocks = await getPrices(asset_json)

  return watchlists_json
}