import React, { useEffect, useState } from 'react';

import styles from '../../css/components/common/FilterComponent.module.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function FilterComponent({ data, onFilter }) {
  const [expression, setExpression] = useState('');
  const [savedExpressions, setSavedExpressions] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleExpressionChange = (event) => {
    setExpression(event.target.value);
  };

  const handleFilterData = () => {

    let data_list = Object.values(data)

    const filtered = data_list.filter((item) => {
      try {
        // Use the user's expression to filter the data
        return eval(expression);
      } catch (error) {
        // If there is a syntax error in the expression, return false
        return false;
      }
    });

    console.log("data_list", data_list[0].tags)
    console.log("filtered", filtered)

    // convert array to object, use ticker as key
    let filtered_obj = {}
    filtered.forEach((item) => {
        filtered_obj[item.ticker] = item
    })

    onFilter(filtered_obj);
  };

  const handleSaveExpression = () => {
    // Save the expression
    setSavedExpressions([...savedExpressions, expression]);
    localStorage.setItem('savedExpressions', JSON.stringify([...savedExpressions, expression]));
  };

  const deleteSavedExpression = (index) => {
    // Remove the expression from the savedExpressions array
    const newSavedExpressions = savedExpressions.filter((expression, i) => i !== parseInt(index, 10));
    setSavedExpressions(newSavedExpressions);
    localStorage.setItem('savedExpressions', JSON.stringify(newSavedExpressions));
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    // Load the saved expressions from localStorage
    const savedExpressionsIn = JSON.parse(localStorage.getItem('savedExpressions'));
    if (savedExpressionsIn) {
      setSavedExpressions(savedExpressionsIn);
    }
  }, []);

  return (
    <div>
      <input type="text" value={expression} onChange={handleExpressionChange} />
      <button onClick={handleFilterData}>Filter</button>
      <button onClick={handleSaveExpression}>Save</button>

      <div className={styles.saved_expressions_container}>
        <span onClick={toggleCollapse}>Saved Filter Expressions</span>
        {isCollapsed ?
          <KeyboardArrowUpIcon onClick={toggleCollapse}/>
        :
          <KeyboardArrowDownIcon onClick={toggleCollapse}/>
        }

        {isCollapsed ?
          <ul className={styles.saved_expression_list}>
            {savedExpressions.map((expression, index) => (
              <li key={index} className={styles.saved_expression} onClick={() => setExpression(expression)}>
                {expression}
                <button type='button' onClick={()=>deleteSavedExpression(index)}>x</button>
              </li>
            ))}
          </ul>
        : null
        }
      </div>

    </div>
  );
}

export default FilterComponent;