import React, { useState, useEffect } from 'react'
import * as d3 from 'd3';


export default function DividendBarChart(props) {
    
    useEffect(() => {
        d3.selectAll("#dividendBarChart > *").remove();

        let bottom_margin = 40
        let top_margin = 40

        // Define the dimensions of our chart
        if (props.parentWidth === 0 || props.parentHeight === 0) {
            return
        }
        const width = props.parentWidth - bottom_margin;
        const height = props.parentHeight - bottom_margin - top_margin;


        // clean dividend data
        let data_in = props.data

        let data_obj = {}

        data_in.map((d) => {
            let val = d[1]
            // remove $
            val = parseFloat(val.replace('$',''))

            let year = new Date(d[0]).getFullYear()
            // convert to number
            year = parseInt(year)
            // console.log("data_in",year)

            if (data_obj[year] === undefined) {
                data_obj[year] = val
            } else {
                data_obj[year] += val
            }
        })

        let data = Object.values(data_obj)
        let years = Object.keys(data_obj).map((d) => parseInt(d))

        let data_array = data.map((d,i) => {
            return {
                year: years[i],
                value: d
            }
        })

        // console.log("data_obj",years)
        // console.log("data_obj",d3.range(d3.min(Object.keys(data_obj)), 2023))

        // Define the scale we'll use for the x-axis
        const x = d3.scaleBand()
            // .domain(Object.keys(data_obj))
            .domain(d3.range(d3.min(Object.keys(data_obj)), 2024))

            // .domain(d3.range(data.length))
            .range([50, width])
            .padding(0.1);


        // Define the scale we'll use for the y-axis
        const y = d3.scaleLinear()
                .domain([0, d3.max(data)])
                .range([height, 0]);

        // Define the SVG element for our chart
        const svg = d3.select("#dividendBarChart")
            .append("svg")
            .attr("width", width + bottom_margin)
            .attr("height", height + bottom_margin + top_margin);

        // Define the bars for our chart
        svg.selectAll("rect")
        .data(data_array)
        .enter()
        .append("rect")
            .attr("class", "bar")
            // .attr("x", (d, i) => x(years[i]))
            .attr("x", d => x(d.year))
            // .attr("x", (d, i) => x(parseInt(Object.keys(data_obj)[i])))
            .attr("y", d => y(d.value) + top_margin)
            .attr("width", x.bandwidth())
            .attr("height", d => height - y(d.value))
            .attr("fill", "steelblue")
            .on('mouseover', handleMouseOver)
            .on('mouseout', handleMouseOut)


        // Define the x-axis for our chart
        const xAxis = d3.axisBottom(x);
        svg.append("g")
        .attr("class", "axis")
        .attr("transform", "translate(0," + (height+top_margin) + ")")
        .call(xAxis);

        // Define the y-axis for our chart
        // const yAxis = d3.axisLeft(y);
        // svg.append("g")
        // .attr("class", "axis")
        // .call(yAxis);

        // Define the y-axis for our chart
        const yAxis = d3.axisLeft(y)
            .ticks(5)
            .tickFormat(d => d + "$")
            .scale(y)

        svg.append("g")
        .attr("class", "axis")
        .attr("transform", "translate(50,40)")
        .call(yAxis);

        //--------------------------------------
        // Tooltip
        //--------------------------------------

        // Create a group (g) element for the tooltip within the SVG
        const tooltip = svg.append('g')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('pointer-events', 'none');

        // Append a rect element for the tooltip background
        tooltip.append('rect')
            .attr('class', 'tooltip-background')
            .attr('width', 0)
            .attr('height', 0)
            .attr('rx', 3)
            .attr('ry', 3)
            .style('fill', 'white')
            .style('stroke', '#ccc')
            .style('stroke-width', '1px');

        // Append a text element for the tooltip content
        tooltip.append('text')
            .attr('class', 'tooltip-text')
            .attr('x', 0)
            .attr('y', 0)
            .style('font-size', '12px')
            .style('text-anchor', 'middle');

        function handleMouseOver(event, d) {
            // Change the color of the hovered bar
            d3.select(event.currentTarget)
                .attr('fill', 'orange');
        
            // Get the position of the hovered bar
            const rect = event.currentTarget;
            const xPos = parseFloat(rect.getAttribute('x'));
            const yPos = parseFloat(rect.getAttribute('y'));
        
            // Clear the text content and update it
            tooltip.select('.tooltip-text')
                .text('')
                .text(d.year + ': $' + d.value.toFixed(2));
        
            // Get the dimensions of the tooltip text
            const tooltipBBox = tooltip.node().getBBox();
        
            // Update the tooltip background size
            tooltip.select('.tooltip-background')
                // .attr('width', tooltipBBox.width + 10)
                .attr('width', tooltipBBox.width)
                // .attr('height', tooltipBBox.height + 6)
                .attr('height', tooltipBBox.height )
                .attr('x', tooltipBBox.x)
                .attr('y', tooltipBBox.y);
        
            // Position the tooltip
            tooltip.attr('transform', `translate(${xPos + x.bandwidth() / 2}, ${yPos - 10})`);
        
            // Show the tooltip
            tooltip.style('opacity', 1);
        }


        function handleMouseOut(event, d) {
            // Reset the color of the bar
            d3.select(event.currentTarget)
                .attr('fill', 'steelblue');

            // Hide the tooltip
            tooltip.transition()
                .duration(0)
                .style('opacity', 0);
        }

    }, [props.data])

    return (
        <div id="dividendBarChart"></div>
    )
}