import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { deleteTransaction } from '../../../lib/aws_api';

import { setTransactionData } from '../../../redux/slices/transactionDataSlice'

// TODO update this to delete
import '../../../css/components/pages/Transactions/AddTransactionModal.css'

export default function DeleteTransactionModal(props) {
    const dispatch = useDispatch()
    let accessToken = useSelector(state => state.accessToken.value)
    let user = useSelector(state => state.user.value)

    const [transaction_type, setTransactionType] = useState('purchase')

    let transaction = props.transaction

    useEffect(() => {
        transaction = props.transaction
    }, [props.transaction])

    function handleTypeSelect(e){
        setTransactionType(e.target.value)
    }

    async function handleSubmit(e){
        e.preventDefault()
        props.loadingFunc(true)

        let transaction_data = await deleteTransaction(accessToken,user,props.row_number)

        dispatch(setTransactionData(transaction_data.transactions))

        props.loadingFunc(false)
    }

    if(!props.open){
        return null
    }

    return (
        <div className='add-transaction-modal'>
            <h2>Delete Transaction</h2>
            <h5>Are you sure you want to delete this transaction?</h5>

            <div className='transaction-details'>
                <div><label>Ticker: </label><p>{transaction.data.ticker}</p></div>
                <div><label>Shares: </label><p>{transaction.data.shares}</p></div>
                <div><label>Purchase Date: </label><p>{transaction.data.purchase_date}</p></div>
                <div><label>Purchase Price: </label><p>{transaction.data.purchase_price}</p></div>
                <div><label>Purchase Fee: </label><p>{transaction.data.purchase_fee}</p></div>
                <div><label>Account: </label><p>{transaction.data.account}</p></div>
            </div>

            <button onClick={handleSubmit}>Yes</button>
            <button>No</button>

            <br/>
            <br/>
        </div>
    )
}
